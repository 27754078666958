<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { object, string } from 'zod';
    import { toTypedSchema } from '@vee-validate/zod';
    import { useField, useForm } from 'vee-validate';
    import FormField from '@/components/form-components/FormField.vue';
    import { hasValidationError } from '@/util/hasValidationError';
    import { useAuthenticationAction, useAuthenticationQuery } from '@/composables/authentication';
    import Button from 'primevue/button';
    import Password from 'primevue/password';
    import Message from 'primevue/message';
    import { computed, ref } from 'vue';
    import ErrorTag from '@/components/ErrorTag.vue';
    import { Navigation } from '@/router/navigation';
    import router from '@/router';
    import { getLogger } from '@/logger/logger';
    import { passwordRequirements } from '@/common/validation/password';
    import PasswordRequirements from '@/common/components/PasswordRequirements.vue';
    import { jwtDecode } from 'jwt-decode';

    const props = defineProps<{
        token: string;
        email: string;
    }>();

    const decodedEmail = computed(() => decodeURIComponent(props.email));

    const { t } = useI18n();
    const logger = getLogger('PasswordResetView');

    const { lengthCheck, numberCheck, letterCheck, validatorFn } = passwordRequirements();

    const showError = ref(false);

    const schema = object({
        password: string()
            .trim()
            .refine(validatorFn, {
                message: t('FORM.PASSWORD_VALIDATION.ERROR'),
            }),
        passwordConfirm: string().refine(areSamePassword, {
            message: t('FORM.PASSWORD_CONFIRM_VALIDATION_TEXT'),
        }),
    });

    const { handleSubmit, errors } = useForm({
        validationSchema: toTypedSchema(schema),
    });
    const { value: password } = useField<string>('password');
    const { value: passwordConfirm } = useField<string>('passwordConfirm');

    const onSubmit = handleSubmit(async (values) => {
        showError.value = false;
        const authenticationAction = useAuthenticationAction();
        try {
            await authenticationAction.resetPassword(decodedEmail.value, props.token, values.password);
            await router.push({ name: Navigation.ResetPasswordSuccess });
        } catch (error) {
            logger.error(error, 'Resetting password failed');
            showError.value = true;
        }
    });

    const { isUserLoggedIn } = useAuthenticationQuery();

    function areSamePassword(value: string): boolean {
        return password.value === value;
    }

    const isTokenExpired = computed(() => {
        try {
            const decoded = jwtDecode(props.token);
            if (decoded == null || decoded.exp == null) {
                return true;
            }

            if (Date.now() >= decoded.exp * 1000) {
                return true;
            }
        } catch {
            // something went wrong with the token, we assume it's invalid at this point
            return true;
        }

        return false;
    });
</script>

<template>
    <div v-if="!isUserLoggedIn" class="flex-container">
        <Message v-if="isTokenExpired" icon="pi pi-exclamation-triangle" severity="warn" :closable="false">
            <div>
                {{ t('LOGIN.RESET_PASSWORD.TOKEN_EXPIRED_DESCRIPTION') }}
            </div>
            <router-link class="link" :to="{ name: Navigation.ForgotPassword }">{{
                t('LOGIN.RESET_PASSWORD.GO_TO_FORGOT_PASSWORD')
            }}</router-link>
        </Message>
        <template v-if="!isTokenExpired">
            <h2>{{ t('LOGIN.RESET_PASSWORD.TITLE') }}</h2>
            <p class="text">{{ t('LOGIN.RESET_PASSWORD.DESCRIPTION', { email: decodedEmail }) }}</p>
            <form class="form" @submit.prevent="onSubmit">
                <div>
                    <FormField :label="t('FORM.PASSWORD')" :error-message="errors.password">
                        <Password
                            v-model="password"
                            input-id="password"
                            class="full-width"
                            :input-class="showError ? 'error-border full-width' : 'full-width'"
                            :feedback="false"
                            :invalid="hasValidationError(errors.password)"
                        />
                    </FormField>
                    <PasswordRequirements
                        :letter-check="letterCheck"
                        :number-check="numberCheck"
                        :length-check="lengthCheck"
                    ></PasswordRequirements>
                    <FormField :label="t('FORM.PASSWORD_CONFIRM')" :error-message="errors.passwordConfirm">
                        <Password
                            v-model="passwordConfirm"
                            input-id="passwordConfirm"
                            class="full-width"
                            :input-class="showError ? 'error-border full-width' : 'full-width'"
                            :feedback="false"
                            :invalid="hasValidationError(errors.passwordConfirm)"
                        />
                    </FormField>
                </div>
                <ErrorTag v-if="showError" :message="t('LOGIN.RESET_PASSWORD.ERROR_SUMMARY')" />
                <div>
                    <Button type="submit" :label="t('LOGIN.RESET_PASSWORD.SAVE_BUTTON')" />
                </div>
            </form>
        </template>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .flex-container {
        margin: auto;
        display: flex;
        flex-direction: column;
        background: main.$color-white;
        padding: main.$spacing-6;
        max-width: 448px;

        :deep(.error-border) {
            border-color: main.$color-error-red;
        }
    }

    .text {
        margin-bottom: 0;
    }

    .form {
        background: main.$color-white;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: main.$spacing-6;
    }

    .link {
        color: main.$color-primary-500;
        text-decoration: none;

        &:hover {
            color: main.$color-primary-darker;
            text-decoration: underline;
        }
    }
</style>
